import React from "react";

const SvgPlus = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="22" y1="11.1333" x2="-4.37114e-08" y2="11.1333" stroke="#252525" />
            <line x1="11.5" y1="2.18557e-08" x2="11.5" y2="22" stroke="#252525" />
        </svg>
    )
}

export default SvgPlus