import React from "react";

const SvgGuukLogo = ({ inverse }) => {
  const primary = inverse ? '#ffffff' : '#252525';
  const secondary = inverse ? '#ffffff' : '#72A325';
  return (
    <svg
      width="65"
      height="26"
      viewBox="0 0 65 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.879 0L48.9927 0.00176801C50.4232 0.0488674 51.5444 1.27452 51.5831 2.72764V7.9835L54.6219 4.42483C55.6038 3.2543 57.3514 3.13655 58.4801 4.1813C59.5629 5.17895 59.6171 6.89165 58.6657 8.02311L56.2922 10.7961L60.1662 15.8164C61.0939 17.0346 60.8541 18.7944 59.6245 19.7054C58.4417 20.5778 56.7792 20.2871 55.882 19.1161L51.5753 13.507L51.5908 16.0996C51.5908 17.6394 50.3381 18.8806 48.8149 18.8255C47.3534 18.7784 46.2168 17.5136 46.2168 16.0289V2.72764C46.2168 1.18782 47.4694 -0.0533596 48.9927 0.00176801L48.879 0Z"
        fill={primary}
      />
      <path
        d="M42.5918 3.89722L42.691 3.89869C44.1215 3.94579 45.2891 5.11685 45.2891 6.61707V11.2917C45.2891 15.4792 42.0802 18.826 38.1367 18.826L37.9751 18.8241C34.1059 18.7341 30.9843 15.4219 30.9843 11.2917V6.61707C30.9843 5.11685 32.1519 3.94579 33.5823 3.89869C34.9045 3.85159 36.3041 4.93595 36.3119 6.61707V11.2917C36.3119 12.5093 37.0928 13.4288 38.1367 13.4288C39.1806 13.4288 39.9615 12.5093 39.9615 11.2917V6.61707C39.977 4.93595 41.3688 3.85159 42.691 3.89869L42.5918 3.89722Z"
        fill={primary}
      />
      <path
        d="M27.3879 3.89722L27.4872 3.89869C28.9177 3.94579 30.0852 5.11685 30.0852 6.61707V11.2917C30.0852 15.4792 26.8763 18.826 22.9328 18.826L22.7712 18.8241C18.9021 18.7341 15.7804 15.4219 15.7804 11.2917V6.61707C15.7804 5.11685 16.948 3.94579 18.3785 3.89869C19.7007 3.85159 21.1003 4.93595 21.108 6.61707V11.2917C21.108 12.5093 21.889 13.4288 22.9328 13.4288C23.9767 13.4288 24.7577 12.5093 24.7577 11.2917V6.61707C24.7732 4.93595 26.165 3.85159 27.4872 3.89869L27.3879 3.89722Z"
        fill={primary}
      />
      <path
        d="M13.4234 3.9154C14.2739 4.3318 14.8616 5.25077 14.8616 6.25645L14.862 10.0782C14.8627 13.3435 14.8645 17.558 14.869 17.8325L14.869 18.0491C14.8208 23.3305 11.714 25.5296 7.45396 25.6464C4.53115 25.7245 0.912424 24.3972 0.858307 21.9298C0.819651 20.4216 1.9872 19.0627 3.57233 19.1017C4.81725 19.1333 5.27349 20.17 7.13694 20.2096C9.60357 20.2412 9.86648 19.1333 9.75824 18.8427C9.65769 18.5911 9.40251 18.5044 9.2015 18.5596C8.9154 18.6382 8.6293 18.7169 8.32773 18.7485C3.83524 19.2826 0 15.7234 0 11.2688C0 7.1128 3.34035 3.73449 7.4385 3.73449C8.4437 3.73449 9.40251 3.93092 10.3072 4.32377C10.3304 4.33929 10.3691 4.3318 10.3846 4.31574C11.2042 3.55412 12.3718 3.39677 13.4234 3.9154ZM7.44623 9.14771C7.1524 9.14771 6.85857 9.20284 6.57252 9.32861C6.07762 9.53253 5.69102 9.9409 5.50542 10.444C5.24251 11.143 5.31987 11.8816 5.72194 12.4709C6.10855 13.0446 6.75033 13.3904 7.4385 13.3904C8.58291 13.3904 9.50307 12.4393 9.5108 11.2688C9.5108 10.5537 9.16285 9.88631 8.57518 9.49346C8.23496 9.26546 7.84835 9.14771 7.44623 9.14771Z"
        fill={primary}
      />
      <path
        d="M62.0784 19.9048C63.5478 19.9048 64.7385 21.1149 64.7385 22.6076C64.7385 24.1004 63.54 25.31 62.0784 25.31C60.6095 25.31 59.4188 24.1004 59.4188 22.6076C59.4188 21.1149 60.6095 19.9048 62.0784 19.9048Z"
        fill={secondary}
      />
    </svg>
  );
};

export default SvgGuukLogo;
