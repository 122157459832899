import React, { useState, useEffect, useRef, useCallback } from 'react'
import { usePageContext } from "../../../shared/components/layout/pageContextProvider";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { sendCookiesPreferences } from '../../hooks/useAnalyticEvent';
import './cookie-consent.scss'


const CookieConsent = () => {
  const { cookieBannerState, setCookieBannerState } = usePageContext()
  const barRef = useRef(null)
  const detailRef = useRef(null)

  const { t } = useTranslation();

  const currentDate = new Date().getTime().toString()

  const cookiesConfigInitial = {
    preferences: true,
    advertising: false,
    analytics: false,
    date: currentDate,
  }

  const cookiesConfigAll = {
    preferences: true,
    advertising: true,
    analytics: true,
    date: currentDate,
  }

  const [cookiesConfig, setCookiesConfig] = useState(cookiesConfigInitial);
  const [height, setHeight] = useState('auto')

  const acceptAll = () => {

    setCookiesConfig(cookiesConfigAll)
    localStorage.setItem("thor-cookies", JSON.stringify(cookiesConfigAll))
    sendCookiesPreferences('afterInit', cookiesConfigAll, "Segunda pantalla", "Aceptar todo")
    setCookieBannerState('hidden')
  }

  const acceptAndSave = () => {
    localStorage.setItem("thor-cookies", JSON.stringify(cookiesConfig))
    sendCookiesPreferences('afterInit', cookiesConfig, "Segunda pantalla", "Aceptar selección")

    setCookieBannerState('hidden');
  }

  const configure = () => {
    setCookieBannerState('detail')
    setWrapperHeight()
  }

  const back = () => {
    setCookieBannerState('bar')
    setWrapperHeight()
    setCookiesConfig(cookiesConfigInitial)
  }

  const setWrapperHeight = useCallback(() => {
    if (cookieBannerState === 'bar' && barRef.current) {
      const barHeight = barRef.current.getBoundingClientRect().height
      setHeight(`${barHeight}px`)
    }
    else if (cookieBannerState === 'detail' && detailRef.current) {
      const detailHeight = detailRef.current.getBoundingClientRect().height
      setHeight(`${detailHeight}px`)
    }
  }, [cookieBannerState, barRef, detailRef, setHeight])

  useEffect(() => {
    const consent1 = localStorage.getItem('ax_cookies_preferences')
    const consent2 = localStorage.getItem('thor-cookies')
    if (consent1 && consent2 && consent1 !== 'undefined' && consent2 !== 'undefined') {
      setCookieBannerState('hidden')
      const userCookiesConfig = JSON.parse(consent1)
      setCookiesConfig(prevCookiesConfig => { return { ...prevCookiesConfig, ...userCookiesConfig } })
      sendCookiesPreferences('init', userCookiesConfig)
    } else {
      setCookieBannerState('bar')
      sendCookiesPreferences('init', cookiesConfigInitial)
    }

    window.addEventListener('resize', setWrapperHeight)
    return () => {
      window.removeEventListener('resize', setWrapperHeight)
    }
  }, [])

  useEffect(() => {
    setWrapperHeight();
  }, [cookieBannerState, setWrapperHeight])

  if (!cookieBannerState || cookieBannerState === 'hidden') return null
  return (
    <>
      <div className={`cookie-consent-wrapper ${cookieBannerState}`} style={{ height }}>
        <div className="cookie-consent-bar" ref={barRef}>
          <div className="wrapper-fluid">
            <p dangerouslySetInnerHTML={{ __html: t("cookies.hello") }}></p>
            <div className="actions-wrapper">
              <button className="btn stroked small" onClick={() => { configure() }}>{t("cookies.configure")}</button>
              <button className="link-accept btn stroked small" onClick={() => { acceptAll() }}>{t("cookies.accept")}</button>
            </div>
          </div>
        </div>
        <div className="cookie-consent-detail" ref={detailRef}>
          <div className="wrapper-fluid">
            <header>
              <h4> {t("cookies.modify")}</h4>
            </header>
            <div className="cookie-consent-terms">
              <div>
                <div className="form-field form-field-checkbox">
                  <label htmlFor="preferences">
                    <input
                      disabled={true}
                      checked={cookiesConfig.preferences}
                      name="preferences"
                      type="checkbox"
                      id="preferences"
                    />
                    <span>
                      <strong>{t("cookies.types.preferences.title")}</strong>
                    </span>
                  </label>
                </div>
                <p>{t("cookies.types.preferences.description")}</p>
              </div>
              <div>
                <div className="form-field form-field-checkbox">
                  <label htmlFor="advertising">
                    <input
                      disabled={false}
                      checked={cookiesConfig.advertising}
                      onChange={() => { setCookiesConfig({ ...cookiesConfig, advertising: !cookiesConfig.advertising }) }}
                      name="advertising"
                      id="advertising"
                      type="checkbox"
                    />
                    <span>
                      <strong>{t("cookies.types.advertising.title")}</strong>
                    </span>
                  </label>
                </div>
                <p>{t("cookies.types.advertising.description")}</p>
              </div>
              <div>
                <div className="form-field form-field-checkbox">
                  <label htmlFor="analytics">
                    <input
                      disabled={false}
                      checked={cookiesConfig.analytics}
                      onChange={() => { setCookiesConfig({ ...cookiesConfig, analytics: !cookiesConfig.analytics }) }}
                      name="analytics"
                      id="analytics"
                      type="checkbox"
                    />
                    <span>
                      <strong>{t("cookies.types.analytics.title")}</strong>
                    </span>
                  </label>
                </div>
                <p>{t("cookies.types.analytics.description")}</p>
              </div>
            </div>
            <div className="actions-wrapper">
              <button className="btn stroked small" onClick={() => { back() }}>{t("cookies.back")}</button>
              <button className="link-accept btn stroked small" onClick={() => { acceptAndSave() }}>{t("cookies.save")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CookieConsent;