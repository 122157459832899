const sendContactForm = ({ data }, successCallback, errorCallback) => {

  const url = process.env.GATSBY_EMAIL_SERVICE_URL

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(res => {
      if (res.ok) {
        successCallback(res)
      }
      else {
        errorCallback(res)
      }
    })
    .catch(error => {
      errorCallback(error)
    })
}

const validateField = (validations, value) => {
  for (let validation of validations) {
    const validationResult = getValidation[validation](value);
    if (!validationResult.valid) {
      return validationResult;
    }
  }
  return { valid: true }
}

const getValidation = {
  required: (value) =>
    value?.trim() !== '' ?
      { valid: true } :
      { valid: false, message: "No te olvides de este campo" },
  email: (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase()) ?
      { valid: true } :
      { valid: false, message: "Revisa este email" }
  },
  number: (value) => {
    const re = /^[0-9\s]*$/;
    return re.test(String(value).toLowerCase()) ?
      { valid: true } :
      { valid: false, message: "Introduce solo números" }
  },
  check: (value) => {
    return value ?
      { valid: true } :
      { valid: false, message: 'Recuerda aceptar nuestra política de privacidad' }
  },


}

export { sendContactForm, validateField }