import React, { createContext, useContext } from "react";
const PageContext = createContext(null);

export const PageContextProvider = ({ pageContext, children }) => {
  return (
    <PageContext.Provider value={pageContext}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
