import React from "react"
import './index.scss'
function BgLines({ type = 'default' }) {
  return (
    <div className={`bg-lines bg-lines--${type}`}>
      <div className={`grid-lines grid-lines--${type}${type === 'default' ? ' wrapper-fluid' : ''}`}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {
          type === 'preloader' && (
            <>
              <span></span>
              <span></span>
            </>
          )
        }
      </div>
    </div>
  );
}

export default BgLines;
