import React, { useState, useEffect } from "react";
import { useI18next, Link, useTranslation } from "gatsby-plugin-react-i18next";
import SvgKirolappLogo from "../../svg/svg-kirolapp-logo";
import SvgGuukLogo from "../../svg/svg-guuk-logo";
import BgLines from "./../bg-lines";
import "./index.scss";

const Header = ({ menuOpenHandler, currentRoute }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { languages, language } = useI18next();
  const { t } = useTranslation();
  function handleScroll(e){
    e.preventDefault();
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    menuOpenHandler(showMobileMenu)
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
   
      window.addEventListener("scroll", handleScroll);
    } else {
      document.body.style.overflow = "visible";

      window.removeEventListener("scroll", handleScroll);
    }

    return () => window.removeEventListener("scroll", handleScroll);

  }, [showMobileMenu]);

  const getLinks = () => {
    return (
      <>
        <li>
          <Link to={language === 'es' ? '/app/' : '/aplikazioa/'}>{t("header.app")}</Link>
        </li>
        <li>
          <Link to={language === 'es' ? '/clubes/' : '/taldeak/'}>{t("header.clubs")}</Link>
        </li>
        <li>
          <Link to={language === 'es' ? '/sobre-kirolapp/' : '/kirolapp-buruz/'}>{t("header.about")}</Link>
        </li>
        <li>
          <Link to={language === 'es' ? '/preguntas/' : '/galderak/'}>{t("header.faqs")}</Link>
        </li>
        {/* 
        <li>
          <Link to="">{t("header.whoFor")}</Link>
        </li>
        <li>
          <Link to="">{t("header.questions")}</Link>
        </li> */}
      </>
    );
  };

  const getLangButtons = () => {
    return languages.map((lng) => (
      <li key={lng}>
        <Link
          className={`btn stroked small ${language === lng ? "" : "inactive"}`}
          to={currentRoute.slugs[lng]}
          language={lng}
        >
          {lng === 'es' ? 'CAS' : 'EUS'}
        </Link>
      </li >
    ));
  };

  const getSponsor = (type = 'mobile') => {
    return (
      <div className={`sponsor sponsor--${type}`} >
        <span>{t("header.projectBy")}</span> <SvgGuukLogo></SvgGuukLogo>
      </div>
    );
  };
  return (
    <>
      <header className="header">
        {getSponsor('mobile')}
        <div className="wrapper-fluid">
          <nav className="nav-main">
            <Link to="/" aria-label={t("header.home")}>
              <SvgKirolappLogo></SvgKirolappLogo>
            </Link>
            <ul className="desktop">{getLinks()}</ul>
            {getSponsor('desktop')}
          </nav>
          <nav className="desktop">
            <ul className="languages">{getLangButtons()}</ul>
          </nav>
          <nav className="mobile">
            <button
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="btn stroked small"
            >
              {showMobileMenu ? t("header.close") : t("header.menu")}
            </button>
          </nav>
        </div>
      </header>
      {showMobileMenu && (
        <div className="mobile-menu">
          <span className="menu-bg-lines">
            <BgLines></BgLines>
          </span>
          <div>
            <ul className="links">{getLinks()}</ul>
            <ul className="langs">{getLangButtons()}</ul>
          </div>
          {/* {getSponsor()} */}
        </div>
      )}
    </>
  );
};

export default Header;
