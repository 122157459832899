import React from "react";

const SvgKirolappLogo = () => {
  return (
    <svg
      width="109"
      height="28"
      viewBox="0 0 109 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 22.3419V2.5569H5.2354V9.923H5.35716L10.3491 2.5569H16.0715L10.1664 10.5926L17.2281 22.3419H11.5057L7.12259 14.7323L5.2354 17.4108V22.3419H0Z"
        fill="#252525"
      />
      <path
        d="M17.7739 22.3419V7.12267H23.0093V22.3419H17.7739Z"
        fill="#252525"
      />
      <path
        d="M25.6296 22.3419V7.12267H29.891L30.2562 9.74037H30.378C30.9462 8.70546 31.6158 7.95465 32.3869 7.48793C33.1783 7.00091 33.9494 6.7574 34.7002 6.7574C35.167 6.7574 35.5424 6.78784 35.8265 6.84872C36.1106 6.8893 36.3642 6.96033 36.5874 7.06179L35.7352 11.5667C35.4511 11.5058 35.1771 11.4551 34.9133 11.4145C34.6698 11.3536 34.3756 11.3232 34.0306 11.3232C33.4827 11.3232 32.9044 11.5159 32.2956 11.9015C31.7071 12.2668 31.2303 12.9263 30.865 13.88V22.3419H25.6296Z"
        fill="#252525"
      />
      <path
        d="M43.8988 22.7071C42.9248 22.7071 41.9812 22.5346 41.0681 22.1897C40.1549 21.8244 39.3432 21.307 38.633 20.6373C37.943 19.9474 37.385 19.1052 36.9589 18.1109C36.553 17.1166 36.3501 15.9904 36.3501 14.7323C36.3501 13.4741 36.553 12.3479 36.9589 11.3536C37.385 10.3593 37.943 9.5273 38.633 8.85765C39.3432 8.16772 40.1549 7.65026 41.0681 7.3053C41.9812 6.94003 42.9248 6.7574 43.8988 6.7574C44.8729 6.7574 45.8063 6.94003 46.6992 7.3053C47.6123 7.65026 48.4139 8.16772 49.1038 8.85765C49.814 9.5273 50.3822 10.3593 50.8083 11.3536C51.2345 12.3479 51.4476 13.4741 51.4476 14.7323C51.4476 15.9904 51.2345 17.1166 50.8083 18.1109C50.3822 19.1052 49.814 19.9474 49.1038 20.6373C48.4139 21.307 47.6123 21.8244 46.6992 22.1897C45.8063 22.5346 44.8729 22.7071 43.8988 22.7071ZM43.8988 18.5066C44.6902 18.5066 45.2483 18.1718 45.5729 17.5022C45.9179 16.8122 46.0904 15.8889 46.0904 14.7323C46.0904 13.5756 45.9179 12.6625 45.5729 11.9928C45.2483 11.3029 44.6902 10.9579 43.8988 10.9579C43.1074 10.9579 42.5392 11.3029 42.1943 11.9928C41.8696 12.6625 41.7073 13.5756 41.7073 14.7323C41.7073 15.8889 41.8696 16.8122 42.1943 17.5022C42.5392 18.1718 43.1074 18.5066 43.8988 18.5066Z"
        fill="#252525"
      />
      <path
        d="M57.9483 22.7071C57.0554 22.7071 56.3046 22.5752 55.6958 22.3114C55.1074 22.0273 54.6305 21.6316 54.2652 21.1243C53.9 20.617 53.6362 20.0184 53.4738 19.3285C53.3318 18.6182 53.2608 17.837 53.2608 16.9847V1.15674H58.4962V17.1673C58.4962 17.6949 58.5875 18.0501 58.7701 18.2327C58.973 18.4153 59.1658 18.5066 59.3485 18.5066C59.4499 18.5066 59.5311 18.5066 59.592 18.5066C59.6731 18.5066 59.7746 18.4863 59.8963 18.4457L60.5051 22.281C60.221 22.4027 59.8659 22.5042 59.4398 22.5854C59.0339 22.6665 58.5368 22.7071 57.9483 22.7071Z"
        fill="#252525"
      />
      <path
        d="M65.3707 22.7071C64.6605 22.7071 64.0213 22.5854 63.4531 22.3419C62.8849 22.0781 62.3979 21.7331 61.9921 21.307C61.6065 20.8808 61.3123 20.3837 61.1094 19.8155C60.9064 19.2473 60.805 18.6487 60.805 18.0196C60.805 16.4368 61.4543 15.199 62.753 14.3061C64.0517 13.393 66.1621 12.7842 69.0842 12.4798C68.9625 11.4246 68.2522 10.897 66.9535 10.897C66.4056 10.897 65.8273 11.0086 65.2185 11.2318C64.6098 11.4348 63.9097 11.7493 63.1183 12.1754L61.292 8.76634C62.3675 8.11699 63.4531 7.61983 64.5489 7.27486C65.6447 6.92989 66.7912 6.7574 67.9884 6.7574C69.9568 6.7574 71.499 7.32559 72.6151 8.46195C73.7515 9.57803 74.3196 11.384 74.3196 13.88V22.3419H70.0583L69.693 20.8808H69.5712C68.9625 21.4287 68.3131 21.8751 67.6232 22.2201C66.9535 22.5448 66.2027 22.7071 65.3707 22.7071ZM67.197 18.6893C67.6232 18.6893 67.9682 18.5979 68.232 18.4153C68.516 18.2124 68.8001 17.9587 69.0842 17.6544V15.5845C67.8261 15.7672 66.9637 16.0411 66.497 16.4064C66.0302 16.7716 65.7969 17.1673 65.7969 17.5935C65.7969 18.324 66.2636 18.6893 67.197 18.6893Z"
        fill="#252525"
      />
      <path
        d="M76.8196 27.699V7.12267H81.081L81.4463 8.52283H81.568C82.1362 7.99523 82.7754 7.56909 83.4856 7.24442C84.1959 6.91974 84.9365 6.7574 85.7076 6.7574C86.6005 6.7574 87.4122 6.94003 88.1427 7.3053C88.8732 7.67056 89.4921 8.18801 89.9994 8.85765C90.527 9.5273 90.9227 10.339 91.1865 11.2927C91.4706 12.2465 91.6127 13.3118 91.6127 14.4888C91.6127 15.8078 91.4301 16.9746 91.0648 17.9892C90.7198 19.0038 90.2531 19.8662 89.6646 20.5764C89.0761 21.2664 88.3964 21.794 87.6253 22.1592C86.8744 22.5245 86.0932 22.7071 85.2815 22.7071C84.0234 22.7071 82.887 22.1998 81.8724 21.1852L82.055 23.4985V27.699H76.8196ZM84.0031 18.4457C84.6119 18.4457 85.1395 18.1617 85.5859 17.5935C86.0323 17.0253 86.2555 16.0107 86.2555 14.5496C86.2555 13.2915 86.0729 12.3885 85.7076 11.8406C85.3627 11.2927 84.8351 11.0188 84.1248 11.0188C83.719 11.0188 83.3537 11.0999 83.0291 11.2623C82.7247 11.4246 82.4 11.7087 82.055 12.1146V17.7152C82.3594 17.9993 82.6739 18.1921 82.9986 18.2936C83.3233 18.395 83.6581 18.4457 84.0031 18.4457Z"
        fill="#252525"
      />
      <path
        d="M93.5631 27.699V7.12267H97.8245L98.1897 8.52283H98.3115C98.8797 7.99523 99.5189 7.56909 100.229 7.24442C100.939 6.91974 101.68 6.7574 102.451 6.7574C103.344 6.7574 104.156 6.94003 104.886 7.3053C105.617 7.67056 106.236 8.18801 106.743 8.85765C107.271 9.5273 107.666 10.339 107.93 11.2927C108.214 12.2465 108.356 13.3118 108.356 14.4888C108.356 15.8078 108.174 16.9746 107.808 17.9892C107.463 19.0038 106.997 19.8662 106.408 20.5764C105.82 21.2664 105.14 21.794 104.369 22.1592C103.618 22.5245 102.837 22.7071 102.025 22.7071C100.767 22.7071 99.6305 22.1998 98.6159 21.1852L98.7985 23.4985V27.699H93.5631ZM100.747 18.4457C101.355 18.4457 101.883 18.1617 102.329 17.5935C102.776 17.0253 102.999 16.0107 102.999 14.5496C102.999 13.2915 102.816 12.3885 102.451 11.8406C102.106 11.2927 101.579 11.0188 100.868 11.0188C100.462 11.0188 100.097 11.0999 99.7725 11.2623C99.4682 11.4246 99.1435 11.7087 98.7985 12.1146V17.7152C99.1029 17.9993 99.4174 18.1921 99.7421 18.2936C100.067 18.395 100.402 18.4457 100.747 18.4457Z"
        fill="#252525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3811 0C21.9489 0 23.2193 1.26871 23.2193 2.83369C23.2193 4.39867 21.9404 5.66682 20.3811 5.66682C18.8138 5.66682 17.5435 4.39867 17.5435 2.83369C17.5435 1.26871 18.8138 0 20.3811 0Z"
        fill="#252525"
      />
    </svg>
  );
};

export default SvgKirolappLogo;
