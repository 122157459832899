import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { sendContactForm, validateField } from "./services/contact.service";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { sendAnalyticEvent } from "../../hooks/useAnalyticEvent";
import { usePageContext } from "../layout/pageContextProvider";
import { CSSTransition } from "react-transition-group";
import "./index.scss";

const ContactForm = ({
  submitSuccessHandler = () => { },
  modal = false,
  hideButton = false,
  disableForm = false,
  isOpenHandler = () => { }
}) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const { initialFormState, formState, handleFormChanges } = usePageContext()

  const initialFormValidation = {
    valid: false,
    fieldsValidation: {
      name: { valid: false, message: null, validations: ["required"] },
      club: { valid: false, message: null, validations: ["required"] },
      phone: {
        valid: false,
        message: null,
        validations: ["required", "number"],
      },
      email: {
        valid: false,
        message: null,
        validations: ["required", "email"],
      },
      privacy: { valid: false, message: null, validations: ["check"] },
    },
  };

  const initialBlurState = {
    name: false,
    club: false,
    email: false,
    phone: false,
    privacy: false,
  };

  const initialFormSendState = {
    sending: false,
    success: null,
    error: null,
  };

  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [blurredState, setBlurredState] = useState(initialBlurState);
  const [formSendState, setFormSendState] = useState(initialFormSendState);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const formRef = useRef(null)

  useEffect(() => {
    isOpenHandler(isFormOpen);
  }, [isFormOpen]);

  const getFormValidation = (state) => {
    let valid = true;
    const fields = Object.keys(formValidation.fieldsValidation);
    let fieldsValidation = {};
    for (let field of fields) {
      const valRes = validateField(
        formValidation.fieldsValidation[field].validations,
        state[field],
        lang
      );
      if (!valRes.valid) valid = false;
      fieldsValidation = {
        ...fieldsValidation,
        [field]: {
          ...formValidation.fieldsValidation[field],
          ...valRes,
        },
      };
    }

    return {
      valid,
      fieldsValidation,
    };
  };

  const getFieldValue = (key, el) => {
    if (key !== "privacy") {
      return el.value;
    }
    if (key === "privacy") {
      return el.checked;
    }
  };

  const handleInputChange = (el, key, blurred = false) => {
    const state = {
      ...formState,
      [key]: getFieldValue(key, el),
    };

    setFormValidation(getFormValidation(state));
    handleFormChanges(state);
    if (blurred) setBlurredState({ ...blurredState, [key]: true });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (formValidation.valid) {
      const success = () => {
        setFormSendState({
          ...formSendState,
          sending: false,
          error: false,
          success: true,
        });

        handleFormChanges(initialFormState);
        setFormValidation(initialFormValidation);
        setBlurredState(initialBlurState);

        submitSuccessHandler();
      };

      const error = () => {
        setFormSendState({
          ...formSendState,
          sending: false,
          error: true,
          success: false,
        });
      };

      setFormSendState({ ...formSendState, sending: true });
      sendContactForm({ data: formState }, success, error);
    }
  };

  const handleButtonClick = (ev) => {
    if (modal && !isFormOpen) {
      setIsFormOpen(!isFormOpen);
      sendAnalyticEvent('customEvent', 'Accion/Envio Formulario', modal ? 'Modal formulario' : 'Sección Contacto', t("home.sectionContact.send"))
    }
  }

  return (
    <CSSTransition in={isFormOpen} classNames="contact-form--modal" timeout={{
      appear: 833,
      enter: 833,
      exit: 333,
    }}
    >
      <div ref={formRef} className={`contact-form${modal ? " contact-form--modal" : ""}${modal && isFormOpen ? " contact-form--open" : ""}`}>
        <form
          className={formValidation.valid ? "form-valid" : "form-invalid"}
          onSubmit={handleSubmit}
        >
          {modal &&

            <div className="form-header">
              <h2>{t("home.sectionContact.formModalTitle")}</h2>
              <p>{t("home.sectionContact.formModalParagraph")}</p>
              <button className="button--unstyled close" onClick={() => {
                formRef.current.classList.remove("contact-form--modal")
                void formRef.current.offsetWidth;
                formRef.current.classList.add("contact-form--modal")

                setIsFormOpen(false)
              }
              }>
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.58984 21.9999L22.3896 1.34131"
                    stroke="#252525"
                    strokeWidth="2"
                  />
                  <path
                    d="M1.58986 1.34131L22.3896 21.9999"
                    stroke="#252525"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>

          }

          <fieldset disabled={disableForm} className="fields-wrapper">
            <div className={`form-field form-field-text f-50${formState.name !== "" ? " has-value" : ""} ${(blurredState.name && !formValidation.fieldsValidation.name.valid) ? ' error' : ''}`}>
              <input
                onChange={(ev) => {
                  handleInputChange(ev.target, "name");
                }}
                onBlur={(ev) => {
                  handleInputChange(ev.target, "name", true);
                }}
                type="text"
                name="name"
                value={formState.name}
                disabled={formSendState.sending}
                id={`field-name${modal ? '-modal' : ''}`}
              />
              <label htmlFor={`field-name${modal ? '-modal' : ''}`}>
                {t("home.sectionContact.labelName")}*
              </label>
              {blurredState.name &&
                !formValidation.fieldsValidation.name.valid && (
                  <p className="alert alert-validation">
                    {t("home.sectionContact.errorName")}
                  </p>
                )}
            </div>
            <div className={`form-field form-field-text f-50${formState.club !== "" ? " has-value" : ""} ${(blurredState.club && !formValidation.fieldsValidation.club.valid) ? ' error' : ''}`}>
              <input
                onChange={(ev) => {
                  handleInputChange(ev.target, "club");
                }}
                onBlur={(ev) => {
                  handleInputChange(ev.target, "club", true);
                }}
                id={`field-club${modal ? '-modal' : ''}`}
                type="text"
                name="club"
                value={formState.club}
                disabled={formSendState.sending}
              />
              <label htmlFor={`field-club${modal ? '-modal' : ''}`}>
                {t("home.sectionContact.labelClub")}*
              </label>
              {blurredState.club &&
                !formValidation.fieldsValidation.club.valid && (
                  <p className="alert alert-validation">
                    {t("home.sectionContact.errorClub")}
                  </p>
                )}
            </div>
            <div className={`form-field form-field-text f-50${formState.email !== "" ? " has-value" : ""} ${(blurredState.email && !formValidation.fieldsValidation.email.valid) ? ' error' : ''}`}>
              <input
                onChange={(ev) => {
                  handleInputChange(ev.target, "email");
                }}
                onBlur={(ev) => {
                  handleInputChange(ev.target, "email", true);
                }}
                id={`field-email${modal ? '-modal' : ''}`}
                type="email"
                name="email"
                value={formState.email}
                disabled={formSendState.sending}
              />
              <label htmlFor={`field-email${modal ? '-modal' : ''}`}>
                {t("home.sectionContact.labelEmail")}*
              </label>
              {blurredState.email &&
                !formValidation.fieldsValidation.email.valid && (
                  <p className="alert alert-validation">
                    {t("home.sectionContact.errorEmail")}
                  </p>
                )}
            </div>
            <div className={`form-field form-field-text f-50${formState.phone !== "" ? " has-value" : ""} ${(blurredState.phone &&!formValidation.fieldsValidation.phone.valid) ? 'error' : ''}`}>
              <input
                onChange={(ev) => {
                  handleInputChange(ev.target, "phone");
                }}
                onBlur={(ev) => {
                  handleInputChange(ev.target, "phone", true);
                }}
                type="text"
                name="phone"
                id={`field-phone${modal ? '-modal' : ''}`}
                value={formState.phone}
                disabled={formSendState.sending}
              />
              <label htmlFor={`field-phone${modal ? '-modal' : ''}`}>
                {t("home.sectionContact.labelPhone")}*
              </label>
              {blurredState.phone &&
                !formValidation.fieldsValidation.phone.valid && (
                  <p className="alert alert-validation">
                    {t("home.sectionContact.errorPhone")}
                  </p>
                )}
            </div>

            <div className="form-field form-field-checkbox f-70">
              <label htmlFor={`field-checkbox${modal ? '-modal' : ''}`}>
                <input
                  disabled={formSendState.sending}
                  checked={formState.privacy}
                  onChange={(ev) => {
                    handleInputChange(ev.target, "privacy", true);
                  }}
                  name="privacy"
                  id={`field-checkbox${modal ? '-modal' : ''}`}
                  type="checkbox"
                />
                <span className="marker"></span>
                <span>
                  <span> {t("home.sectionContact.labelPrivacy1")} {" "}</span>
                  <Link
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(t("home.sectionContact.privacyLink"), "_blank");
                    }}
                    to={t("home.sectionContact.privacyLink")}
                    state={{ homeInnerNavigation: false }}
                  >
                    {t("home.sectionContact.labelPrivacy2")}
                  </Link>
                </span>
              </label>
              {blurredState.privacy &&
                !formValidation.fieldsValidation.privacy.valid && (
                  <p className="alert alert-validation privacy-error">
                    {t("home.sectionContact.errorPrivacy")}
                  </p>
                )}
            </div>
            {formSendState.error && (
              <p className="alert alert-error">
                {t("home.sectionContact.errorForm")}
              </p>
            )}

            {formSendState.success && (
              <p className="alert alert-success">
                {t("home.sectionContact.successForm")}
              </p>
            )}
          </fieldset>
          {
            !hideButton &&
            <div className="actions-wrapper">
              <button
                disabled={(modal && isFormOpen && (!formValidation.valid || formSendState.sending)) || (!modal && (!formValidation.valid || formSendState.sending)) ? true : false}
                type={`${!modal || (modal && isFormOpen) ? 'submit' : 'button'}`}
                className={`form-button${!modal ? ' form-button--default' : ''}${modal ? ' form-button--modal' : ''}`}
                onClick={() => handleButtonClick()}
              >
                {modal &&
                  <>
                    <span className="open">{t("home.sectionContact.cta")}</span>
                    <span className="send">
                      <span className="text">{t("home.sectionContact.send")}</span>
                      <span className="icon">
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.32227 14.4339L12.3343 3.42187"
                            stroke="#252525"
                            strokeWidth="1.0661"
                          />
                          <path
                            d="M7.17993 3.42188H12.3345V8.57645"
                            stroke="#252525"
                            strokeWidth="1.0661"
                          />
                        </svg>
                      </span>
                    </span>
                  </>
                }
                {
                  !modal &&
                  <span className="text">{t("home.sectionContact.send")}</span>
                }
              </button>
            </div>
          }

        </form>
      </div>
    </CSSTransition>
  );
};

export default ContactForm;
