import React from "react";
import { SvgGuukLogo, SvgCajaRuralLogo } from "../../svg";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { InView } from 'react-intersection-observer';
import "./index.scss";

const Footer = ({ footerInViewHandler }) => {
  const { t } = useTranslation();

  return (
    <InView as="footer" onChange={(inView, entry) => footerInViewHandler(inView)}>

      <div className="wrapper-fluid grid">
        <div className="logos">
          <SvgGuukLogo inverse={true}></SvgGuukLogo>
          <SvgCajaRuralLogo></SvgCajaRuralLogo>
        </div>

        <ul>
          <li>
            <Link to={t("footer.legal.slug")} >{t("footer.legal.title")}</Link>
          </li>
          <li>
            <Link to={t("footer.privacy.slug")}>{t("footer.privacy.title")}</Link>
          </li>
          <li>
            <Link to={t("footer.cookies.slug")}>{t("footer.cookies.title")}</Link>
          </li>
          {/* <li>
            <Link to={t("footer.conditions.slug")}>{t("footer.conditions.title")}</Link>
          </li> */}
          {/* <li>
            <Link to={t("footer.quality.slug")}>{t("footer.quality.title")}</Link>
          </li>
          <li>
            <Link to={t("footer.map.slug")}>{t("footer.map.title")}</Link>
          </li> */}
        </ul>
      </div>

    </InView>
  );
};

export default Footer;
