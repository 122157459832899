import React, { useEffect, useState } from "react";
import Header from "../header";
import { PageContextProvider } from "./pageContextProvider";
import Footer from "../footer";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import BgLines from "./../bg-lines";
import CookieConsent from "../../components/cookie-consent/cookie-consent";
import { sendAnalyticEvent } from "../../hooks/useAnalyticEvent";
import ContactForm from "../contact-form";
import "../../scss/styles.scss";
import "./index.scss";

const Layout = ({
  children,
  pageContext,
  metas = { title: "", description: "" },
  noindex = false
}) => {
  const { language } = useI18next();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFooterInView, setIsFooterInView] = useState(false);
  const [cookieBannerState, setCookieBannerState] = useState(null) // hidden | bar | detail

  const { route } = pageContext;

  const initialFormState = {
    name: "",
    club: "",
    email: "",
    phone: "",
    privacy: false
  };

  const handleMenuOpen = (isOpen) => {
    setIsMenuOpen(isOpen);
  };

  const handleFooterInView = (inView) => {
    setIsFooterInView(inView);
  };

  const [formState, setFormState] = useState(initialFormState);
  const [isSectionContactInView, setIsSectionContactInView] = useState(false);
  const [isSectionAdminInfoInView, setIsSectionAdminInfoInView] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);


  const handleFormChanges = (newFormState) => {
    setFormState({ formState, ...newFormState });
  };

  const handleSectionContactInView = (inView) => {
    setIsSectionContactInView(inView);
  }

  const handleSectionAdminInfoInViewHandler = (inView) => {
    setIsSectionAdminInfoInView(inView);
  }

  const handleFormIsOpen = (isOpen) => {
    setIsFormOpen(isOpen)
  }

  pageContext = {
    isFormOpen,
    initialFormState,
    formState,
    handleFormChanges,
    handleSectionContactInView,
    handleSectionAdminInfoInViewHandler,
    isFooterInView,
    setIsFooterInView,
    cookieBannerState,
    setCookieBannerState,
    ...pageContext
  }

  //   const [menuState, setMenuState] = useState({ open: false });
  //   const [footerIn, setFooterIn] = useState(false);
  //   const [headerCollapsed, setHeaderCollapsed] = useState(false);

  //   const toggleMenu = () => {
  //     const open = !menuState.open;
  //     setMenuState({ ...menuState, open });
  //   };

  //   useScrollPosition(({ prevPos, currPos }) => {
  //     checkHeaderCollapsed({ prevPos, currPos });
  //   });

  useEffect(() => {
    sendAnalyticEvent('pageLoaded')
  }, [])

  return (
    <PageContextProvider pageContext={pageContext}>
      <Helmet title={metas.title} htmlAttributes={{ lang: language }}>
        <link rel="manifest" href="/manifest.webmanifest" />
        <meta name="description" content={metas.description} />
        {noindex &&
          <meta name={`robots`} content={`noindex, nofollow`} />
        }
        {/* <link
            rel="apple-touch-icon"
            href="/pwaicons/icon-apple-192x192.png"
          /> */}
        {/* <link
            rel="canonical"
            href={buildHrefLang(languages, lang, route)}
            hrefLang={lang.key}
          /> */}
        {/* {metas.ogImage ? (
            <meta property="og:image" content={metas.ogImage} />
          ) : (
            <meta property="og:image" content={"/home/workoholics-og.png"} />
          )} */}
        {/* {languages?.langs.map((alternate, key) => {
            if (alternate.key === lang.key) return null;
            return (
              <link
                key={key}
                rel="alternate"
                href={buildHrefLang(languages, alternate, route)}
                hrefLang={alternate.key}
              />
            );
          })} */}

        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GATSBY_GUUK_GTM_CODE}');`}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>

      { /* Google Tag Manager (noscript) */}
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GUUK_GTM_CODE}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      {/* End Google Tag Manager (noscript) */}

      <div
        className={`site-wrapper${isMenuOpen ? " menu-open" : " menu-hidden"}${isFooterInView ? " footer-in-view" : ""
          }`}
      >
        <BgLines></BgLines>
        <Header
          menuOpenHandler={(isOpen) => handleMenuOpen(isOpen)}
          currentRoute={route}
        />

        <main>
          {children}

          <ContactForm
            modal={true}
            hideButton={false}
            hide={isSectionContactInView && !isSectionAdminInfoInView}
            isOpenHandler={(isFormOpen) => handleFormIsOpen(isFormOpen)}
          />

        </main>

        <Footer
          footerInViewHandler={(inView) => handleFooterInView(inView)}
        ></Footer>

        <CookieConsent />
      </div>
    </PageContextProvider>
  );
};

export default Layout;
