
const sendAnalyticEvent = (type, eventAction = '', eventLabel = '', element = '') => {

    const ThorAX = window.ThorAX

    var CONFIG_AX = { funnelTypes: {}, parseItemToProduct: function (item) { return item; }, parseExternalPromotionToGenericPromotion: function (item) { return item; } }
    var analyticService = new ThorAX.AnalyticsService(CONFIG_AX, 'kirolapp-web')

    if (type === 'pageLoaded') {
        analyticService.pageLoaded({})
    } else if (type === 'formErrors') {
        analyticService.sendFormErrors(
            [] // Form's fields with errors list
        )
    } else if (type === 'customEvent') {
        analyticService.sendCustomEvent(
            {
                dataToSend: {
                    'eventAction': eventAction, // Accion/Envio Formulario | Accion/Mas Informacion | Accion/Boton
                    'eventLabel': eventLabel, // Web section
                    'element': element // Element label
                }
            }
        )
    }

}

const sendCookiesPreferences = (type, cookiesConfig, eventLabel = '', element = '') => {

    const ThorAX = window.ThorAX
    const ThorAxJs = window.ThorAxJs


    var CONFIG_AX = { funnelTypes: {}, parseItemToProduct: function (item) { return item; }, parseExternalPromotionToGenericPromotion: function (item) { return item; } }
    var analyticService = new ThorAX.AnalyticsService(CONFIG_AX, 'kirolapp-web')

    if (type === 'init') {
        analyticService.initCookiesPreferences({ cookiesConfig: cookiesConfig })
    } else if (type === 'afterInit') {
        ThorAxJs.sendCookiesPreferences({ cookiesConfig: cookiesConfig, ctaText: eventLabel, axLabel: element })
    }
}

export { sendAnalyticEvent, sendCookiesPreferences }